import React from 'react';
import { graphql, withPrefix } from 'gatsby';
import styled from '@emotion/styled';
import FunctinosTemplate from 'components/Templates/FunctinosTemplate';
import { FluidObject } from 'gatsby-image';
import { Helmet } from 'react-helmet';

const Component = styled.div`
  width: 100%;
  height: 100%;
`;

interface usageAnalysisProps {
  location: {
    pathname: string;
  };
  data: {
    logoNerdFactoryImg: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    topFunctionImg: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    imgDashboard: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    imgUserAnalytics: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    thumbnail: {
      publicURL: string;
    };
  };
}

const usageAnalysis = ({ data, location }: usageAnalysisProps) => {
  const title = 'VODA | 기능 | 웹 사이트 사용 분석';
  const desc =
    '웹 사이트 사용 분석으로 얻은 탄탄한 기초 데이터, 다양한 인사이트를 제공합니다';
  return (
    <Component>
      <Helmet>
        <title>VODA | 기능 | 웹 사이트 사용 분석</title>
        <meta
          name="description"
          content="웹 사이트 사용 분석으로 얻은 탄탄한 기초 데이터, 다양한 인사이트를 제공합니다"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />

        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="<https://voda.nerdfactory.ai/functions/usageAnalysis>"
        />
        <meta property="og:image" content={data.thumbnail.publicURL} />
        <meta property="og:description" content={desc} />
        <meta property="og:site_name" content={title} />
        <script src={withPrefix('script.js')} type="text/javascript" />
      </Helmet>
      <FunctinosTemplate
        location={location}
        title={'VODA 쉽고\\n편리한 분석'}
        header={{
          main: '웹 사이트 사용 분석으로 얻은\\n탄탄한 기초 데이터,\\n다양한 인사이트를 제공합니다',
          sub: '웹 사이트 사용 분석',
        }}
        isAIvory={false}
        functionData={[
          {
            IllustImg: data.imgDashboard.childImageSharp.fluid,
            descData: {
              title: '전체 웹 사이트의\\n기초 통계를\\n한 눈에 볼 수 있습니다',
              list: [
                '1. 사용자 수',
                '2. 방문 횟수',
                '3. 평균 페이지뷰 수',
                '4. 평균 이용 시간',
                '5. 웹사이트 사용 분석',
                '6. 사용 환경 분석',
              ],
            },
            engTitle: 'DASHBOARD',
            korTitle: '서비스 이용 대시보드',
            isBackGround: true,
            isDescLeft: false,
            width: 648,
            key: '0',
          },
          {
            IllustImg: data.imgUserAnalytics.childImageSharp.fluid,
            descData: {
              title:
                '사용자 수, 페이지뷰 수\\n그리고 방문횟수를\\n분석할 수 있습니다',
              list: [
                '1. 시간별, 요일별, 주별, 월별 분석',
                '2. 신규 사용자 수 VS 재방문 사용자 수 비교 분석',
                '3. 방문 집중 시간 및 집중 요일 분석',
              ],
            },
            engTitle: 'DETAILS',
            korTitle: '사용자 수, 페이지뷰 수, 방문 횟수',
            isBackGround: false,
            isDescLeft: true,
            width: 648,
            key: '1',
          },
        ]}
        img={data.topFunctionImg.childImageSharp.fluid}
        logoNerdFactoryImg={data.logoNerdFactoryImg.childImageSharp.fluid}
      />
    </Component>
  );
};

export default usageAnalysis;

export const queryData = graphql`
  query {
    logoNerdFactoryImg: file(name: { eq: "logo-nerd-factory@3x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    topFunctionImg: file(name: { eq: "img-top-function@3x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgDashboard: file(name: { eq: "img-dashboard" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgUserAnalytics: file(name: { eq: "img-userAnalytics-v2" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    thumbnail: file(name: { eq: "thumbnail-1280-720" }) {
      publicURL
    }
  }
`;
